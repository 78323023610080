<template>
  <v-container fluid class="py-0">
    <heading>
      <h2>{{ $t("clinics") }}</h2>
      <p>{{ $t("clinics-.sub-heading") }}</p>
      <template #actions>
        <v-btn
          color="primary"
          elevation="0"
          @click="$router.push({ name: 'clinics-new' })"
        >
          <v-img max-width="22px" :src="require(`@/assets/plus.svg`)" class="mr-2" />
          {{ $t("clinics-.new") }}
        </v-btn>
      </template>
    </heading>
    <clinics-table />
  </v-container>
</template>

<script>
import Heading from "../components/Heading";
import ClinicsTable from "./Table";

export default {
  mixins: [],
  components: {
    Heading,
    ClinicsTable,
  },
  data: () => ({}),
  computed: {},
  watch: {},
  created: async function () {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
